import { OS } from '../models/indexPageVariant';

export const detectPlatform = (querystring: string): string => {
  const searchParams = new URLSearchParams(querystring);
  let platformName: string = OS.WINDOWS;

  if (searchParams.get('platform')) {
    platformName = searchParams.get('platform').toLowerCase();
  } else if (typeof window !== 'undefined') {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    if (/Android/.test(userAgent)) {
      platformName = OS.ANDROID;
    }
    if (
      /iPhone/.test(platform) ||
      /iPad/.test(platform) ||
      /iOS/.test(platform) ||
      /(iPad)/.test(userAgent) ||
      (platform === 'MacIntel' && navigator.maxTouchPoints > 1)
    ) {
      platformName = OS.IOS;
    }
    if (/Win/.test(platform)) {
      platformName = OS.WINDOWS;
    }
    if (/Mac/.test(platform)) {
      platformName = OS.MAC;
    }
  }
  return platformName;
};
